import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  const RightColumnUnitName = "RightColumn1";
  const LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  const MPUCenterRightUnitName = "MPUCenterRight";
  const MPUTopRightUnitName = "MPUTopRight";
  const LeaderBelowTitleUnitName = "Leader_BellowTitle";
  const MPUParallaxUnitName = "MPU_Parallax";
  let StickyBannerUnitName = "StickyBannerDesktop";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  // Define Ad Unit Pathways
  const RightColumnPathway = "RightColumn1";
  const LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  const MPUCenterRightPathway = "MPUCenterRight";
  const MPUTopRightPathway = "MPUTopRight";
  const LeaderBelowTitlePathway = "Leader_BellowTitle";
  const MPUParallaxPathway = "MPU_Parallax";
  let StickyBannerPathway = "StickyBannerDesktop";
  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }

  // prettier-ignore
  let adUnits = [
    {
      condition: window.screen.width > 990 && !checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"),
      id: "RightColumn",
      name: `${RightColumnUnitName}`,
      path: `/76730613/${RightColumnPathway}`,
      sizes: window.screen.width > 1440 ? [ [300, 600], [160, 600], [300, 250], ] : [[160, 600]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        // //{bidder: 'appnexus', params: {placementId: '13715698'}},
        { bidder: "adform", params: { mid: 1707886 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // {bidder: 'ix', params: {siteId: 612746, size: [160, 600]}},
        // // {bidder: 'justpremium', params: {zone: 115712}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526101", delDomain: "lqr-d.openx.net" }, },
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_MPUTopRight1'}},
        // //{ bidder: 'richaudience', params: { pid: 'CGbrucxlFe', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141228 } },
        { bidder: "sovrn", params: { tagid: 1141227 } },
        { bidder: "sovrn", params: { tagid: 1142133 } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        // //  { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_RightColumnHalfPage_Prebid"} }
        // {bidder: 'triplelift', params: {inventoryCode: " TrendscatchersCOM_desktop_RightColumnHalfPage_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "AaveLaVxmNY7Fd1C9jHEB8zB" }, },
        { bidder: "sharethrough", params: { pkey: "kfKjgezX8dPSur4FlayZMp5g" }, },
        { bidder: "sharethrough", params: { pkey: "xne64BpdEhZGQ6frsuMCobhI" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 548276061 }, },
      ],
    },
    {
      condition: checkStickybanner(),
      id: "StickyBanner",
      name: StickyBannerUnitName,
      path: `/76730613/${StickyBannerPathway}`,
      sizes: window.screen.width < 812 ? [ [320, 50], [300, 50], [360, 50], [320, 100], [300, 100], [360, 100], ] : [[728, 90]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'StickyBanner', environment: 'desktop', placement: `${StickyBannerPathway}` } },
        { bidder: "adform", params: { mid: 1707902 } },
        // {bidder: 'ix', params: {siteId: 697607, size: [728, 90]}},
        { bidder: "sovrn", params: { tagid: 1141230 } },
        // //{bidder: 'appnexus', params: {placementId: '22129022'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_StickyBanner'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_desktop_StickyBannerDesktop_Prebid"}},
        { bidder: "criteo", params: { networkId: 8147 } },
        // {bidder: 'justpremium', params: {zone: 115712}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526102", delDomain: "lqr-d.openx.net" }, },
        // //{ bidder: 'richaudience', params: { pid: '0scZyiMm8e', supplyType: 'site' }},
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "qToESITMdC9zrNox7aBxMlXd" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 678524856 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'StickyBanner', environment: 'mobile', placement: `${StickyBannerPathway}` } },
        // //{bidder: 'appnexus', params: {placementId: '18069675'}},
        { bidder: "adform", params: { mid: 1707918 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // {bidder: 'ix', params: {siteId: 612758, size: [320, 50]}},
        // {bidder: 'ix', params: {siteId: 612758, size: [360, 50]}},
        // {bidder: 'ix', params: {siteId: 612758, size: [300, 50]}},
        // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526103", delDomain: "lqr-d.openx.net" }, },
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_StickyBanner'}},
        // //{ bidder: 'richaudience', params: { pid: '0scZyiMm8e', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141260 } },
        { bidder: "sovrn", params: { tagid: 1142132 } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_StickyBannerMobile_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        // { bidder: 'sharethrough', params: {pkey: 'lcjha5IXHDe4Odts1hGglNK1'} },
        { bidder: "sharethrough", params: { pkey: "SAoCiAPvnTYXrkzO5xigpY2o" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 874442853 }, },
      ],
    },
    {
      condition: window.screen.width < 812,
      id: "MPUCenterRight",
      name: MPUCenterRightUnitName,
      path: `/76730613/${MPUCenterRightPathway}`,
      sizes: [ [300, 250], [336, 280], [320, 100], [1, 1], ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUCenterRight', environment: 'mobile', placement: `${MPUCenterRightPathway}` } },
        //  // {bidder: 'appnexus', params: {placementId: '13715695'}},
        { bidder: "adform", params: { mid: 1707888 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        //   {bidder: 'ix', params: {siteId: 612753, size: [300, 250]}},
        //   {bidder: 'ix', params: {siteId: 612753, size: [336, 280]}},
        //   {bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
        //   {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526089", delDomain: "lqr-d.openx.net" }, },
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_MPUCenterRight'}},
        //   //{ bidder: 'richaudience', params: { pid: 'DxgLRqBxjs', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141232 } },
        { bidder: "sovrn", params: { tagid: 1142129 } },
        { bidder: "sovrn", params: { tagid: 1142128 } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_MPUCenterRight_Prebid"}},
        //   {bidder: 'ogury', params: { assetKey: 'OGY-D61575877C9B', adUnitId: 'c6ce0c30-17e3-013b-8433-3794722aeb85', skipSizeCheck: true }},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "zh0ZemMPfi1aYDiFnKwUTu0I" }, },
        { bidder: "sharethrough", params: { pkey: "M1XExRTNKW0n1wy1IsC9s2Al" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 213381248 }, },
      ],
    },
    {
      condition: window.screen.width > 812,
      id: "LeaderDesktopInfinite",
      name: LeaderDesktopInfiniteUnitName,
      path: `/76730613/${LeaderDesktopInfinitePathway}`,
      sizes: [[728, 90]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeaderDesktopInfinite', environment: 'desktop', placement: `${LeaderDesktopInfinitePathway}` } },
        //  // {bidder: 'appnexus', params: {placementId: '20295574'}},
        { bidder: "adform", params: { mid: 1707899 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        //   {bidder: 'ix', params: {siteId: 639600, size: [728, 90]}},
        //   //{ bidder: 'ix', params: { siteId: 538242, size: [300, 100] }},
        //   // {bidder: 'justpremium', params: {zone: 115712}},
        //   //{ bidder: 'districtm', params: { placementId: 19614578}},
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        {bidder: 'openx', params: {unit: '559526085', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_LeaderDesktopInfinite'}},
        //   //{ bidder: 'richaudience', params: { pid: 'EOOmDSzb4r', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141182 } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "XHk9YgZfnIQ6Qa7PRzPqIyRI" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 915879418 }, },
      ],
    },
    {
      condition: false,
      id: "MPU_Parallax",
      name: MPUParallaxUnitName,
      path: `/76730613/${MPUParallaxPathway}`,
      sizes: [[300,600], [300,250], [336,280], [320,50], [160,600], [120,600], [320,480]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:`${MPUParallaxPathway}` } },
        { bidder: "adform", params: { mid: 1707912 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // //  { bidder: 'appnexus', params: {placementId: '22738661'} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,250]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [320,480]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,250]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [300,600]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [160,600]} },
        //   { bidder: 'ix', params: {siteId: 715016, size: [320,50]} },
        //   { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: "openx", params: { unit: "559526090", delDomain: "lqr-d.openx.net" }, },
        //   { bidder: 'sovrn', params:{ tagid: 958574 }},
        //   { bidder: 'sovrn', params:{ tagid: 958575 }},
        //   { bidder: 'sovrn', params:{ tagid: 958576 }},
        //   { bidder: 'sovrn', params:{ tagid: 958577 }},
        { bidder: "sovrn", params: { tagid: 1141241 } },
        { bidder: "sovrn", params: { tagid: 1141240 } },
        { bidder: "sovrn", params: { tagid: 1141181 } },
        { bidder: "sovrn", params: { tagid: 1141238 } },
        { bidder: "sovrn", params: { tagid: 1141237 } },
        { bidder: "sovrn", params: { tagid: 1141236 } },
        //   // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tc_com_mobile_parallax' } },
        //   // { bidder: 'teads', params: { pageId: '121182', placementId: '135713'} },
        //   { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
        //   { bidder: 'triplelift', params: {inventoryCode: 'TrendscatchersCOM_mobile_MPUParallax_Prebid'} },
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        //   { bidder: 'sharethrough', params: {pkey: 'GxE6xUtah3bdKxqYAqXJCxta'} },
        //   { bidder: 'sharethrough', params: {pkey: 'gStrjCgLRKNr9FO4eFZJ71pE'} },
        //   { bidder: 'sharethrough', params: {pkey: 'SRoW29ZAntCHzfAX4p7T7pGn'} },
        //   { bidder: 'sharethrough', params: {pkey: 'kTGp4L8rVW0utl4eE0f3ZnQo'} },
        //   { bidder: 'sharethrough', params: {pkey: 'PNgnsCk5iMMjEvLQASv53SOe'} },
        { bidder: "sharethrough", params: { pkey: "pAajEaWZMeUm42sBwo48Aj1b" } },
        { bidder: "sharethrough", params: { pkey: "lNFYb9OB7yJe8JgKW8ZU1u2f" } },
        { bidder: "sharethrough", params: { pkey: "jLzo76PAjLNIzEnONUWuDI2O" } },
        { bidder: "sharethrough", params: { pkey: "zIR7yf1BDszTSYQKw5OX0HwO" } },
        { bidder: "sharethrough", params: { pkey: "4ZqDQA34rdFEWtV6Mwton3BW" } },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 636231821 }, },
      ],
    },
    {
      condition:  window.screen.width < 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      id: "LeaderBelowTitle",
      name: `${LeaderBelowTitleUnitName}`,
      path: `/76730613/${LeaderBelowTitlePathway}`,
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [[728, 90]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}` } },
        // {bidder: 'appnexus', params: {placementId: '13715686'}},
        { bidder: "adform", params: { mid: 1707882 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // { bidder: 'ix', params: {siteId: 612749, size: [728, 90]}},
        // { bidder: 'ix', params: { siteId: 538242, size: [336, 280] }},
        // { bidder: 'ix', params: { siteId: 538242, size: [300, 100] }},
        // { bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526084", delDomain: "lqr-d.openx.net" }, },
        // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_Leader_bellowTitle'}},
        // { bidder: 'richaudience', params: { pid: 'fZrQUDlp0W', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141183 } },
        //   { bidder: 'teads', params: {pageId: '121182', placementId: '131481'} },
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "ZmD9rgYftZTVIhnuqjRmrTtO" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
        // {bidder: 'appnexus', params: {placementId: '13715686'}},
        { bidder: "adform", params: { mid: 1707887 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // {bidder: 'ix', params: {siteId: 612754, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 612754, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
        // {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526086", delDomain: "lqr-d.openx.net" }, },
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_Leader_bellowTitle'}},
        //   //{ bidder: 'richaudience', params: { pid: 'yuEeeriz0A', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141231 } },
        { bidder: "sovrn", params: { tagid: 1142120 } },
        { bidder: "sovrn", params: { tagid: 1142121 } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '116591' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "zXoeDoQOlCZ6iXqpG4Sm1n1u" }, },
        { bidder: "sharethrough", params: { pkey: "QxfNi42ZhXuxuTRX7dKwp9j1" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
      ],
    },
    {
      condition: window.screen.width < 812 && !checkUtmSourceStartsWith('fb-'),
      id: "MPUTopRight",
      name: `${MPUTopRightUnitName}`,
      path: `/76730613/${MPUTopRightPathway}`,
      sizes: [ [300, 250], [336, 280], [320, 100], ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUTopRight', environment: 'mobile', placement: `${MPUTopRightPathway}` } },
        // //{bidder: 'appnexus', params: {placementId: '13715693'}},
        { bidder: "adform", params: { mid: 1707889 } },
        { bidder: "criteo", params: { networkId: 8147 } },
        // {bidder: 'ix', params: {siteId: 612750, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 612750, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
   //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 339895831 }, },
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "openx", params: { unit: "559526091", delDomain: "lqr-d.openx.net" }, },
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'SNAP_MPUTopRight'}},
        // //{ bidder: 'richaudience', params: { pid: '94tKaTDqO9', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141234 } },
        { bidder: "sovrn", params: { tagid: 1142127 } },
        { bidder: "sovrn", params: { tagid: 1142126 } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135713'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_MPUTopRight_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "p7U2tkGTWoWrIpRt6yKP9uEx" }, },
        { bidder: "sharethrough", params: { pkey: "hgEhzpKUsitynOmoe6Zmoo5f" }, },
      ],
    },
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      // Right
      // rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 612746, size:[300, 600]} });
      // rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 612746, size: [300, 250]}});
      // rcMissingIX.bidders.push({ bidder: 'sovrn', params: {tagid: 1103061}});
    }
  return adUnits;

  function checkStickybanner() {
    if (
      !(checkUtmSourceStartsWith("fb-") && !checkUtmSourceStartsWith("twtr_"))
    ) {
      return window.screen.width < 812;
    } else {
      return false;
    }
  }
}
