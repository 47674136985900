import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // prettier-ignore
  const bidders = [
    { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
    { bidder: "adform", params: { mid: 1707868 } },
    { bidder: 'openx', params: {unit: '559526092', delDomain: 'lqr-d.openx.net'}},
    { bidder: "sovrn", params: { tagid: 1141206 } },
    { bidder: "vidazoo", params: { cId: "647870b8e6c93f675397f31e", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
    { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
    { bidder: "sharethrough", params: { pkey: "HAmb0i2H3Ugl5ruW4hch5kUk" }, },
    { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
  ];

  // prettier-ignore
  const mobileBidders = [
    { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
    { bidder: "adform", params: { mid: 1707874 } },
    { bidder: 'openx', params: {unit: '559526093', delDomain: 'lqr-d.openx.net'}},
    { bidder: "sovrn", params: { tagid: 1141242 } },
    { bidder: "sovrn", params: { tagid: 1142125 } },
    { bidder: "sovrn", params: { tagid: 1142124 } },
    { bidder: "vidazoo", params: { cId: "647870b8e6c93f675397f31e", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
    { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
    { bidder: "sharethrough", params: { pkey: "HhMuz8KZ9yHklM4ylIohlYcn" }, },
    { bidder: "sharethrough", params: { pkey: "LB8S9uS8i44rDGJ1XvnZttlk" }, },
    { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
  ]

  // prettier-ignore
  return [
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "LeaderBelowTitle",
      name: "NEXT_Leader_BellowTitle",
      path: "/76730613/NEXT_Leader_BellowTitle",
      sizes: window.screen.width < 1024 ? [[728, 90]] : [[970, 90]],
      bidders: bidders,
      mobile_bidders: mobileBidders,
    },
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_") && window.screen.width < 812,
      id: "LeaderBelowTitle_1",
      name: "NEXT_Leader_BellowTitle",
      path: "/76730613/NEXT_Leader_BellowTitle",
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [[970, 90]],
      mobile_bidders: mobileBidders,
    },
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "LeaderBelowTitle_2",
      name: "NEXT_Leader_BellowTitle",
      path: "/76730613/NEXT_Leader_BellowTitle",
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [[970, 90]],
      mobile_bidders: mobileBidders,
    },
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "LeaderBelowTitle_4",
      name: "NEXT_Leader_BellowTitle",
      path: "/76730613/NEXT_Leader_BellowTitle",
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [[970, 90]],
      mobile_bidders: mobileBidders,
    },
    {
      condition: window.screen.width > 812,
      id: "RightColumn",
      name: "NEXT_RightColumnHalfPage1",
      path: "/76730613/NEXT_RightColumnHalfPage1",
      sizes: window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: "adform", params: { mid: 1707872 } },
        { bidder: "sovrn", params: { tagid: 1141225 } },
        { bidder: "sovrn", params: { tagid: 1141223 } },
        { bidder: "sovrn", params: { tagid: 1141222 } },
        { bidder: 'openx', params: {unit: '559526101', delDomain: 'lqr-d.openx.net'}},
        { bidder: "vidazoo", params: { cId: "647870b8e6c93f675397f31e", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        { bidder: "sharethrough", params: { pkey: "yTVwMegcdatCVef9AOaKHQA3" }, },
        { bidder: "sharethrough", params: { pkey: "IOmOBRPahxfuB6Ea2hfxED8v" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 548276061 }, },
      ],
    },
  ];
}
