import { isBrowser, checkUtmSourceStartsWith, isMobile } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // The current template
  const template = "NEXT";

  // Define Ad unit names
  const RightColumnUnitName = `${template}_RightColumnHalfPage1`;
  const LeftColumnUnitName = `${template}_LeftColumnHalfPage`;
  const MPUCenterRightUnitName = `${template}_MPUCenterRight`;
  const MPUTopRightUnitName = `${template}_MPUTopRight`;
  const LeaderBelowTitleUnitName = `${template}_Leader_BellowTitle`;
  const MPUBelowNextUnitName = `${template}_MPUBelowNext`;
  let StickyBannerUnitName = `${template}_StickyBannerDesktop`;
  if (window.screen.width < 812) {
    StickyBannerUnitName = `${template}_StickyBannerMobile`;
  }

  // Define Ad Unit Pathways
  // Original
  const RightColumnPathway = `${template}_RightColumnHalfPage1`;
  const LeftColumnPathway = `${template}_LeftColumnHalfPage`;
  const MPUCenterRightPathway = `${template}_MPUCenterRight`;
  const MPUTopRightPathway = `${template}_MPUTopRight`;
  const LeaderBelowTitlePathway = `${template}_Leader_BellowTitle`;
  const MPUBelowNextPathway = `${template}_MPUBelowNext`;
  let StickyBannerPathway = `${template}_StickyBannerDesktop`;
  if (window.screen.width < 812) {
    StickyBannerPathway = `${template}_StickyBannerMobile`;
  }

  // prettier-ignore
  let adUnits = [
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "LeaderBelowTitle",
      name: LeaderBelowTitleUnitName,
      path: `/76730613/${LeaderBelowTitlePathway}`,
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [[728, 90]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}` } },
        { bidder: "adform", params: { mid: 1707868 } },
        // //{bidder: 'appnexus', params: {placementId: '20942283'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // {bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'openx', params: {unit: '559526092', delDomain: 'lqr-d.openx.net'}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        // //{bidder: 'pubmatic',params: {publisherId: '159940', adSlot: 'com_desktop_next_Leader_bellowTitle'}},
        // //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141206 } },
        // { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "HAmb0i2H3Ugl5ruW4hch5kUk" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` } },
        { bidder: "adform", params: { mid: 1707874 } },
        // //{bidder: 'appnexus', params: {placementId: '20942283'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm',  params: { placementId: 16005275 } },
        // //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        // {bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526093', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_Leader_bellowTitle'}},
        // //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141242 } },
        { bidder: "sovrn", params: { tagid: 1142125 } },
        { bidder: "sovrn", params: { tagid: 1142124 } },
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "HhMuz8KZ9yHklM4ylIohlYcn" }, },
        { bidder: "sharethrough", params: { pkey: "LB8S9uS8i44rDGJ1XvnZttlk" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 273381276 }, },
      ],
    },
    {
      condition: window.screen.width < 812,
      id: "MPUTopRight",
      name: MPUTopRightUnitName,
      path: `/76730613/${MPUTopRightPathway}`,
      sizes: [ [300, 250], [336, 280], [320, 100], ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUTopRight', environment: 'mobile', placement: `${MPUTopRightPathway}` } },
        { bidder: "adform", params: { mid: 1707876 } },
        // //{bidder: 'appnexus', params: {placementId: '20942284'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // //{ bidder: 'districtm', params: { placementId: 16005279 } },
        // //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
        // {bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
        // {bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526097', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUTopRight'}},
        // //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141245 } },
        { bidder: "sovrn", params: { tagid: 1142137 } },
        { bidder: "sovrn", params: { tagid: 1142138 } },
        // //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "12mSUQPtQNbqfx5CupMjOMb6" }, },
        { bidder: "sharethrough", params: { pkey: "m6xEDWQGZNhtCIKIoGTaEmDO" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 339895831 }, },
      ],
    },
    {
      condition: window.screen.width > 812,
      id: "RightColumn",
      name: RightColumnUnitName,
      path: `/76730613/${RightColumnPathway}`,
      sizes: window.screen.width > 1440 ? [ [300, 600], [160, 600], [300, 250], ] : [[160, 600]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        { bidder: "adform", params: { mid: 1707872 } },
        { bidder: "sovrn", params: { tagid: 1141225 } },
        { bidder: "sovrn", params: { tagid: 1141223 } },
        { bidder: "sovrn", params: { tagid: 1141222 } },
        //  // {bidder: 'appnexus', params: {placementId: '20942290'}},
        //   {bidder: 'criteo', params: {networkId: 8147}},
        //   //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        //   {bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
        //   //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526101', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUTopRight1'}},
        //   //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //   { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "yTVwMegcdatCVef9AOaKHQA3" }, },
        { bidder: "sharethrough", params: { pkey: "IOmOBRPahxfuB6Ea2hfxED8v" }, },
        { bidder: "sharethrough", params: { pkey: "jXIwB0gm0VVRQS8rWdARGRWg" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 548276061 }, },
      ],
    },
    {
      condition: window.screen.width > 812 && !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "LeftColumn",
      name: LeftColumnUnitName,
      path: `/76730613/${LeftColumnPathway}`,
      sizes: window.screen.width > 1440 ? [ [300, 600], [160, 600], [300, 250], ] : [[160, 600]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'LeftColumn', environment: 'desktop', placement: `${LeftColumnPathway}` } },
        { bidder: "adform", params: { mid: 1707904 } },
        // {bidder: 'criteo', params: {networkId: 347220}},
        // {bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: "sovrn", params: { tagid: 1141207 } },
        { bidder: "sovrn", params: { tagid: 1141209 } },
        { bidder: "sovrn", params: { tagid: 1141208 } },
        // //{bidder: 'appnexus', params: {placementId: '22129020'}},
        // {bidder: 'openx', params: {unit: '543986374', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_LeftColumnHalfPage'}},
        // { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "EEUnds0wbzaEvVcYEwDiBd1V" } },
        { bidder: "sharethrough", params: { pkey: "RZk7MuWGaUjRssK3InxcJ476" } },
        { bidder: "sharethrough", params: { pkey: "piJzFiz8cUSPkcvIhOy6k1IS" } },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 548276061 }, },
      ],
    },
    {
      condition: !checkUtmSourceStartsWith("twtr_") && !checkUtmSourceStartsWith("yahoo_"),
      id: "StickyBanner",
      name: StickyBannerUnitName,
      path: `/76730613/${StickyBannerPathway}`,
      sizes: window.screen.width < 812 ? [ [320, 50], [300, 50], [360, 50], [320, 100], [300, 100], [360, 100], ] : [[728, 90]],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'StickyBanner', environment: 'desktop', placement: `${StickyBannerPathway}` } },
        { bidder: "adform", params: { mid: 1707903 } },
        // {bidder: 'ix', params: {siteId: 697608, size: [728, 90]}},
        { bidder: "sovrn", params: { tagid: 1141226 } },
        // //{bidder: 'appnexus', params: {placementId: '22129021'}},
        // {bidder: 'criteo', params: {networkId: 347220}},
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526099', delDomain: 'lqr-d.openx.net'}},
        // {bidder: 'justpremium', params: {zone: 115712}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_StickyBanner'}},
        // {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_desktop_NEXT_StickyBannerDesktop_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "nx2L0umx0FaAdcXBuYU9gu7V" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 678524856 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'StickyBanner', environment: 'mobile', placement: `${StickyBannerPathway}` } },
        { bidder: "adform", params: { mid: 1707877 } },
        //  // {bidder: 'appnexus', params: {placementId: '20942287'}},
        //   {bidder: 'criteo', params: {networkId: 8147}},
        //   //{ bidder: 'districtm', params: { placementId: 18069184} },
        //   //{ bidder: 'districtmDMX',params: {dmxid: 479166, memberid: 101868} },
        //   {bidder: 'ix', params: {siteId: 623542, size: [320, 50]}},
        //   {bidder: 'ix', params: {siteId: 623542, size: [300, 50]}},
        //   {bidder: 'ix', params: {siteId: 623542, size: [360, 50]}},
        //   {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526100', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_StickyBanner'}},
        //   //{ bidder: 'richaudience', params: { pid: '7DEfe5PFrk', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141250 } },
        { bidder: "sovrn", params: { tagid: 1142130 } },
        //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_StickyBannerMobile_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "hUTrx4ooPd3BI5UricGRWLd0" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 874442853 }, },
      ],
    },
    {
      id: "MPUBelowNext",
      name: `${MPUBelowNextUnitName}`,
      path: `/76730613/${MPUBelowNextPathway}`,
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], ] : [ [300, 250], [336, 280], ],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUBelowNext', environment: 'desktop', placement: `${MPUBelowNextPathway}` } },
        { bidder: "adform", params: { mid: 1707869 } },
        // //{bidder: 'appnexus', params: {placementId: '20942291'}},
        // {bidder: 'criteo', params: {networkId: 8147}},
        // {bidder: 'ix', params: {siteId: 623497, size: [300, 250]}},
        // {bidder: 'ix', params: {siteId: 623497, size: [336, 280]}},
        // //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        // //{ bidder: 'districtm', params: {placementId: 16152397} },
        // //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
        // {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526095', delDomain: 'lqr-d.openx.net'}},
        // // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUBelowNext'}},
        // //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141210 } },
        { bidder: "sovrn", params: { tagid: 1142143 } },
        { bidder: "sovrn", params: { tagid: 1142145 } },
        // {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
        // {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUBelowNext_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        // {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        // {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "SW5nff0gPX1Aq2K3UG2wJi3i" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 213381248 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:`${MPUBelowNextPathway}`} },
        { bidder: "adform", params: { mid: 1707873 } },
        //  // { bidder: 'appnexus', params: {placementId: '20942291'} },
        //   { bidder: 'criteo', params: { networkId: 8147 } },
        //   { bidder: 'ix', params: {siteId: 671485, size:[300, 250]} },
        //   { bidder: 'ix', params: {siteId: 671485, size:[336, 280]} },
        //   //{ bidder: 'justpremium', params: {zone: 115712} },
        //   //{ bidder: 'districtm', params: {placementId: 16152397} },
        //   //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
        //   { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: {unit: '559526095', delDomain: 'lqr-d.openx.net'} },
        //   // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'com_mobile_next_MPUBelowNext' } },
        //   //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141243 } },
        { bidder: "sovrn", params: { tagid: 1142136 } },
        { bidder: "sovrn", params: { tagid: 1142135 } },
        //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        //   { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
        //   { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_mobile_NEXT_MPUBelowNext_Prebid"} },
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "gpSBWaTHsSV06eei8R2ie4uK" }, },
        { bidder: "sharethrough", params: { pkey: "aKPhtmTAPLm7rcipmPSBrh1n" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 275368544 }, },
      ],
    },
    {
      id: "MPUCenterRight",
      name: MPUCenterRightUnitName,
      path: `/76730613/${MPUCenterRightPathway}`,
      sizes: window.screen.width < 812 ? [ [300, 250], [336, 280], [320, 100], [1, 1], ] : [ [300, 250], [336, 280], [1, 1], ],
      bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUCenterRight', environment: 'desktop', placement: `${MPUCenterRightPathway}` } },
        { bidder: "adform", params: { mid: 1707870 } },
        //  // {bidder: 'appnexus', params: {placementId: '20942285'}},
        //   {bidder: 'criteo', params: {networkId: 8147}},
        //   {bidder: 'ix', params: {siteId: 623504, size: [300, 250]}},
        //   {bidder: 'ix', params: {siteId: 623504, size: [336, 280]}},
        //   {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   //{ bidder: 'districtm',  params: { placementId: 16005292 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526096', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUCenterRight'}},
        //   //{ bidder: 'richaudience', params: { pid: 'i6doEjb0Cb', supplyType: 'site' }},
        { bidder: "sovrn", params: { tagid: 1141215 } },
        { bidder: "sovrn", params: { tagid: 1142141 } },
        { bidder: "sovrn", params: { tagid: 1142142 } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "bdBYrPMOMbcku4x3Sa6hYRsJ" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 275368544 }, },
      ],
      mobile_bidders: [
        { bidder: "onetag", params: { pubId: "654b33df39ee492", }, },
        { bidder: 'adagio', params: { organizationId: '1067', site: 'ediario-com', adUnitElementId: 'MPUCenterRight', environment: 'mobile', placement: `${MPUCenterRightPathway}` } },
        { bidder: "adform", params: { mid: 1707875 } },
        // //  {bidder: 'appnexus', params: {placementId: '20942285'}},
        //   {bidder: 'criteo', params: {networkId: 8147}},
        //   {bidder: 'ix', params: {siteId: 623524, size: [300, 250]}},
        //   {bidder: 'ix', params: {siteId: 623524, size: [336, 280]}},
        //   {bidder: 'ix', params: {siteId: 623524, size: [320, 100]}},
        //   {bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //   //{ bidder: 'districtm',  params: { placementId: 16005292 } },
        //   //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
        //   {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '559526096', delDomain: 'lqr-d.openx.net'}},
        //   // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUCenterRight'}},
        //   //{ bidder: 'richaudience', params: { pid: 'QLncydO1NV', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 1141244}},
        { bidder: 'sovrn', params: {tagid: 1142140}},
        { bidder: 'sovrn', params: {tagid: 1142139}},
        //   //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        //   {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
        //   {bidder: 'ogury', params: { assetKey: 'OGY-D61575877C9B', adUnitId: 'c6ce0c30-17e3-013b-8433-3794722aeb85', skipSizeCheck: true }},
        //   {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
        { bidder: "vidazoo", params: { cId: "627a472a29a8f3230ff8577f", pId: "59ac17c192832d0011283fe3", bidFloor: "0.05", }, },
        { bidder: "smartadserver", params: { siteId: 597050, pageId: 1826582, formatId: 116604 }, },
        //   {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //   {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: "sharethrough", params: { pkey: "pQNdMLMpbaHVtcqdJwQriLbD" }, },
        { bidder: "sharethrough", params: { pkey: "3NjS8y9xiw4f6W6XFMoEMHC9" }, },
        { bidder: "medianet", params: { cid: "8CUJF5HOT", crid: 213381248 }, },
      ],
    },
  ];

  return adUnits;
}
